export const placementCVList = [
  {
    id: 108,
    name_:'Anubhav Prasad',
    title:'Navi Technologies | EC',
    dept:'EC',
    category: 'data',
    company: "Navi Technologies",
    cover: 'assets/images/anubhavprasad_cv.png',
    profilecolor: 'green',
  },
  {
    id: 109,
    name_:'Omee Katrodia',
    title:'Flipkart | AE',
    dept:'AE',
    category: 'finance',
    company: "Flipkart",
    cover: 'assets/images/omeekatrodia_cv.png',
    profilecolor: 'green',
  },
  {
    id: 110,
    name_:'Divyanshu Gupta',
    title:'Navi Technologies | BT',
    dept:'BT',
    category: 'product',
    company: "Navi Technologies",
    cover: 'assets/images/divyanshugupta_cv.png',
    profilecolor: 'green',
  },
  {
    id: 111,
    name_:'Ankita Das',
    title:'Qualcomm | EC',
    dept:'EC',
    category: 'core',
    company: "Qualcomm",
    cover: 'assets/images/ankitadas_cv.png',
    profilecolor: 'green',
  },
  {
    id: 112,
    name_:'Shubham Jaiswal',
    title:'Flipkart | ME',
    dept:'ME',
    category: 'finance',
    company: "Flipkart",
    cover: 'assets/images/shubhamjaiswal_cv.png',
    profilecolor: 'green',
  },
  {
    id: 70,
    name_:'Swagat Susmoy Sahoo',
    title: 'American Express | ME',
    dept:'ME',
    category: 'data',
    company: "American Express",
    cover: '/assets/images/swagat_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 71,
    name_:'Singh Sumeet Subodh',
    title: 'Collins Aerospace | ME',
    dept:'ME',
    category: 'core',
    company: "Collins Aerospace",
    cover: '/assets/images/sumeet_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 72,
    name_:'Soumyajit Chakraborty',
    title: 'TSMC | MA',
    dept:'MA',
    category: 'software',
    company: "TSMC",
    cover: '/assets/images/soumyajit_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 73,
    name_:'Shrey Sharma',
    title: 'American Express | AE',
    dept:'AE',
    category: 'software',
    company: "American Express",
    cover: '/assets/images/shrey_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 75,
    name_:'Mohit Gupta',
    title: 'Apple | EC',
    dept:'EC',
    category: 'software',
    company: "Apple",
    cover: '/assets/images/mohit_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 77,
    name_:'Gauri Sanjay Karkhanis',
    title: 'American Express | AR',
    dept:'AR',
    category: 'data',
    company: "American Express",
    cover: '/assets/images/gauri_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 78,
    name_:'Debraj Chatterjee',
    title: 'Bain & Co. | IM',
    dept:'IM',
    category: 'consultancy',
    company: "Bain & Co.",
    cover: '/assets/images/debraj_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 79,
    name_:'Ayush Kumar Rai',
    title: 'TSMC | EC',
    dept:'EC',
    category: 'core',
    company: "TSMC",
    cover: '/assets/images/ayush_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 80,
    name_:'Avilipsa Tripathy',
    title: 'Cairn Oil & Gas Vedanta Ltd | GG',
    dept:'GG',
    category: 'core',
    company: "Cairn Oil & Gas Vedanta Ltd",
    cover: '/assets/images/avilipsa_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 81,
    name_:'A Ashwin Sai',
    title: 'Apollo | CS',
    dept:'CSE',
    category: 'software',
    company: "Apollo",
    cover: '/assets/images/ashwin_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 82,
    name_:'Abhishek Kumar Roy',
    title: 'Airbus | MT',
    dept:'MT',
    category: 'core',
    company: "Airbus",
    cover: '/assets/images/abhishek_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 83,
    name_:'Abhishant Kumar',
    title: 'Rakuten | IM',
    dept:'IM',
    category: 'software',
    company: "Rakuten",
    cover: '/assets/images/abhishant_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 84,
    name_:'Abhilash Sinha',
    title: 'BNY Mellon | CH',
    dept:'CH',
    category: 'software',
    company: "BNY Mellon",
    cover: '/assets/images/abhilash_cv.jpg',
    profilecolor: '#FFC0CB',
  },
  {
    id: 85,
    name_:'Suranjan Mondal',
    title: 'Morgan stanley | MA',
    dept:'MA',
    category: 'quant',
    company: "Morgan Stanley",
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*tx9LZVfovurikcwk7-1YQg.png',
    profilecolor: '#FFC0CB',
  },
    {
      id: 86,
      name_:'Shikhar Goel',
      title: 'OLA | ME',
      dept:'ME',
      category: 'core',
      company: "Ola",
      cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*NOE7JVyRUxzmGk4e5n9yZA.png',
      profilecolor: '#FFC0CB',
    },
    {
      id: 87,
      name_:'Yashwanth Pavilla',
      title: 'Texas | ECE',
      dept:'ECE',
      category: 'core',
      company: "Texas Instruments",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*EIk09v-jQbxFi1S_V27cHw.png',
      profilecolor: '#FFC0CB',
    },
    {
      id: 88,
      name_:'GV Sai Harshit',
      title: ' Bajaj|AE ',
      dept:'AE',
      category: 'core',
      company: "Bajaj",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*dlR4BoasGVz45DrBSacdwA.png',
      profilecolor: '#FFC0CB',
    },
    {
      id: 89,
      name_:'Chitranshu Ranjan',
      title: 'ICICI | MF',
      dept:'MF',
      category: 'finance',
      company: "ICICI",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/0*Q2hZoPN4LQ1mPGL4',
      profilecolor: 'orange',
    },
    {
      id: 90,
      name_:'Arka Mandal',
      title: 'Tiger analytics | ME',
      dept:'ME',
      category: 'data',
      company: "Tiger Analytics",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*4kGW5YLLRqu6tqL7-Ky_5w.png',
      profilecolor: 'Red',
    },
    {
      id: 91,
      name_:'N Abhilaash Reddy',
      title: 'JLR | ECE',
      dept:'ECE',
      category: 'core',
      company: "JLR",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*qjGu_mOVPnjKSApkSCScXA.png',
      profilecolor: 'green',
    },
    
    {
      id: 92,
      name_:'Abhijeet Mahato',
      title: 'Groww | AG',
      dept:'AG',
      category: 'product',
      company: "Groww",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*z1Mcz3TwMxdq8y7nI17w1g.png',
      profilecolor: 'green',
    },
    {
      id: 93,
      name_:'Saikat Mondal',
      title: 'Rakuten | ECE',
      dept:'ECE',
      category: 'data',
      company: "Rakuten",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*qKSog_JRLvdhxzoeyzikXg.png',
      profilecolor: 'green',
    },
    {
      id: 94,
      name_:'Yash P. Butala',
      title: 'Graviton | CSE',
      dept:'CSE',
      category: 'quant',
      company: "Graviton",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*J5ZRb_Z-7EYGGUJh1GsQiQ.jpeg',
      profilecolor: 'green',
    },
    {
      id: 95,
      name_:'Bharat Chaudhary',
      title: 'Wells Fargo | MA',
      dept:'MA',
      category: 'software',
      company: "Wells Fargo",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*Gab6XFgDXT5pDwV4-t1oow.jpeg',
      profilecolor: 'green',
    },
    {
      id: 96,
      name_:'Pratyusha Mondal',
      title: 'Accenture | MF',
      dept:'MF',
      category: 'consultancy',
      company: "Accenture",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*X2YT7OE1EFvE1TjafO60vg.jpeg',
      profilecolor: 'green',
    },
    {
      id: 97,
      name_:'Sohan Rudra',
      title: 'Google | MA',
      dept:'MA',
      category: 'software',
      company: "Google",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*MF6ghtK6GwkLhMefdRku-Q.jpeg',
      profilecolor: 'green',
    },
    {
      id: 98,
      name_:'Anuraag Bhattacharya',
      title:'Mercedes Benz | EE',
      dept:'EE',
      category: 'data',
      company: "Mercedes Benz",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*Ftjk8HOYu-MXfk62CtqUPA.jpeg',
      profilecolor: 'green',
    },
    {
      id: 99,
      name_:'Shakul Pathak',
      title:'Dr Reddys | CH',
      dept:'CH',
      category: 'core',
      company: "Dr Reddys",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*bdb_yVQPZfxuSCSIUJ11Kg.jpeg',
      profilecolor: 'green',
    },
    {
      id: 100,
      name_:'Veligeti Vineeth',
      title:'Cisco|CSE',
      dept:'CSE',
      category: 'software',
      company: "Cisco",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*E-yKZVsREMpO9T1nv1_DFw.jpeg',
      profilecolor: 'green',
    },
    {
      id: 101,
      name_:'Raj Kumar Singh',
      title:'JPMC | ME',
      dept:'ME',
      category: 'quant',
      company: "JPMC",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*3csde1hEKZYzwk7cNcc1jQ.jpeg',
      profilecolor: 'green',
    },
    {
      id: 102,
      name_:'Seelam Shiva Surya',
      title:'Western digitals| EE',
      dept:'EE',
      category: 'core',
      company: "Western digitals",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*J6O8rB6PVLg7EMBnr-El4A.jpeg',
      profilecolor: 'green',
    },
    {
      id: 103,
      name_:'Jogadenu Kalyanraman',
      title:'Qualcomm| EE',
      dept:'EE',
      category: 'core',
      company: "Qualcomm",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*bRE3mOY1XWMpAvyQ2pmEsg.png',
      profilecolor: 'green',
    },
    {
      id: 104,
      title:'BNY Melon | IM',
      dept:'IM',
      name_:'Visesh Kaushik K.',
      category: 'software',
      company: "BNY Melon",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*bYzejXxQsBhTMsYZF2a5fA.jpeg',
      profilecolor: 'green',
    },
    {
      id: 105,
      title:'Google | ECE',
      dept:'ECE',
      name_:'Tias Mondal',
      category: 'software',
      company: "Google",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*AIuux4W2zN--iyxbNMa_uA.jpeg',
      profilecolor: 'green',
    },
    {
      id: 106,
      title:'Microsoft | CE',
      dept:'CE',
      name_:'Abhay P S Bhadoriya',
      category: 'software',
      company: "Microsoft",
      cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*EKVnM-BMtb-ey5eoHiVOGw.png',
      profilecolor: 'green',
    },
    {
      id: 107,
      name_:'Renu T. Rathod',
      title:'Mercedes Benz | CE',
      dept:'CE',
      category: 'data',
      company: "Mercedes Benz",
      cover: 'https://miro.medium.com/v2/resize:fit:828/0*XBPsFlQU5yfFXgXk',
      profilecolor: 'green',
    }
    

  ];
      
  