export const blogList = [
 
  {
    id: 1,
    title: 'Morgan Stanley | Compilation',
    category: 'Quant',
    company: 'Morgan Stanley',
    subCategory: ['adrenaline', 'stay-fit', 'lifestyle'],
    description: "The Associate role at Morgan Stanley involves a good mix of finance, data, and coding.",
    createdAt: 'November 20, 2022',
    cover: 'https://miro.medium.com/max/1100/1*NqIH-nzNZOQBGrKWqAM8LA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-morgan-stanley-compilation-49c325742a6e',
  },
  {
    id: 2,
    title: 'OLA | Compilation',
    category: 'Core',
    company: 'Ola',
    subCategory: ['e-commerce store', 'clothing', 'shopping store'],
    description:
      "People who are interested in working for core Electrical companies can apply for this job.",
    createdAt: 'November 13, 2022',
    cover: 'https://miro.medium.com/max/1100/1*9wKxQYLK_lQ2JHzJ2qg5jQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-ola-compilation-3ede15cdfe0e',
  },
  {
    id: 3,
    title: 'Eaton | Compilation',
    category: 'Software',
    company: 'Eaton',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'November 07, 2022',
    cover: 'https://miro.medium.com/max/1100/1*MfV2DxJrZjMuOYCjOh-TsA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-eaton-compilation-3a01711f64f3',
  },
  {
    id: 4,
    title: 'Texas Instruments | Yashwanth Pavilla',
    category: 'Core',
    company: 'Texas Instruments',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'November 03, 2022',
    cover: 'https://miro.medium.com/max/1100/1*Rb4iuedO1RdOmlMkHre1cg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-texas-instruments-yashwanth-pavilla-1affcd69d378',
  },
  {
    id: 5,
    title: 'Bajaj Auto | G V Sai Harshit',
    category: 'Core',
    company: 'Bajaj',
    subCategory: ['frontend', 'ui/ux', 'design'],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    createdAt: 'November 01, 2022',
    cover: 'https://miro.medium.com/max/1100/1*od371qTZ-Rjt_j1dKB6FEA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-bajaj-auto-g-v-sai-harshit-5edc0ba07419',
  },
  {
    id: 6,
    title: 'ICICI Bank | Chitranshu Ranjan',
    category: 'Finance',
    company: 'ICICI Bank',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'October 30, 2022',
    cover: '/assets/images/chitranshu.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-icici-bank-chitranshu-ranjan-ad6f70acd830',
  },
  {
    id: 7,
    title: 'LAM Research | Vembakam Theja',
    category: 'Core',
    company: 'LAM Research',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'October 28, 2022',
    cover: 'https://miro.medium.com/max/828/1*aF-BU7Sc8I4Gsx4RNBeV8Q.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-lam-research-vembakam-theja-ecb9e87c70a8',
  },
  {
    id: 8,
    title: 'American Express | Adithya K S',
    category: 'Software',
    company: 'American Express',
    subCategory: ['bbq', 'food', 'lifestyle'],
    description:
      "The test for the management trainee profile is heavy in mathematics.",
    createdAt: 'October 25, 2022',
    cover: 'https://miro.medium.com/max/1100/1*h4YK_osnmVIwYQ9kTGn2Lg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-american-express-adithya-k-s-cf523320e0f2',
  },
  {
    id: 9,
    title: 'Tiger Analytics | Compilation',
    category: 'Data',
    company: 'Bajaj',
    subCategory: ['beaches', 'sea', 'holidays'],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    createdAt: 'October 23, 2022',
    cover:
      'https://miro.medium.com/max/1100/1*1gR6mwr_6yCFSE6b9k_u0A.webp',
      medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-tiger-analytics-compilation-3cce900f1d4a',
  },
  {
    id: 10,
    title: 'APT Portfolio | Sudhamsh Kukkadapu',
    category: 'Data',
    company: 'APT Portfolio',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'October 22, 2022',
    cover: 'https://miro.medium.com/max/1100/0*IF304qq8z_l1vc3n',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-apt-portfolio-sudhamsh-kukkadapu-2d270e0894c7',
  },
 
  {
    id: 11,
    title: 'Honeywell | Harshit Jain',
    category: 'Software',
    company: 'Honeywell',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    createdAt: 'October 19, 2022',
    cover: '/assets/images/harshit.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-honeywell-harshit-jain-edbfb691d86a',
  },
  {
    id: 12,
    title: 'Morgan Stanley | Tanmay Shah',
    category: 'Finance',
    company: 'Morgan Stanley',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "It is a client-facing job and a collaborative profile so interpersonal skills are also very important.",
    createdAt: 'October 12, 2022',
    cover: 'https://miro.medium.com/max/786/1*YefRyNoF2MRajp3QIrCcPQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-morgan-stanley-tanmay-shah-ee794e317a43',
  },
  {
    id: 13,
    title: 'Jaguar Land Rover India Limited | Narapureddy Abhilaash Reddy',
    category: 'Core',
    company: 'Jaguar Land Rover',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Students who want to pursue their career in the core electronics sector should apply for this job.",
    createdAt: 'October 10, 2022',
    cover: '/assets/images/abhilash.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-jaguar-land-rover-india-limited-narapureddy-abhilaash-reddy-e8dfec9a9b5d',
  },
  {
    id: 14,
    title: 'Groww | Abhijeet Mahato',
    category: 'Product',
    company: 'Groww',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Students interested or inclined to work in the field of strategy or planning must try this profile.",
    createdAt: 'October 9, 2022',
    cover: 'https://miro.medium.com/max/1100/1*7ieqp6yhwrveM8rqlIDwzA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-groww-abhijeet-mahato-6d449dbe4cac',
  },
  {
    id: 15,
    title: 'Rakuten Mobile Inc. | Saikat Mandol',
    category: 'Data',
    company: 'Rakuten Mobile',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Even for the data science profile, there would be an initial coding round to shortlist students.",
    createdAt: 'October 8, 2022',
    cover: 'https://miro.medium.com/max/1100/1*1G4G1qiMmSEwIAOLpDfF-Q.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-rakuten-mobile-inc-saikat-mandol-eef4db00fd1f',
  },
  {
    id: 16,
    title: 'Graviton Research Capital LLP | Yash Butala',
    category: 'Software',
    company: 'Graviton',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone interested in trading or computer systems must apply for the job.",
    createdAt: 'October 7, 2022',
    cover: 'https://miro.medium.com/max/1100/1*srk5gqX3H2p68hfyzff4sw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-graviton-research-capital-llp-yash-butala-fd6bf9fdda9e',
  },
  {
    id: 17,
    title: 'DC Advisory | Ishan Das',
    category: 'Finance',
    company: 'DC Advisory',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Since the nature of the job is client-facing, they evaluate your communication skills and depth of knowledge.",
    createdAt: 'October 6, 2022',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-dc-advisory-ishan-das-1697cc851705',
  },
  {
    id: 18,
    title: 'Da Vinci Derivatives | Kousshik Raj',
    category: 'Software',
    company: 'Da Vinci Derivatives',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "I think people who are very interested in programming and Systems, in particular, will enjoy the work.",
    createdAt: 'October 6, 2022',
    cover: 'https://miro.medium.com/max/1100/1*5v8zPWuTqtPCwDrrJmebrw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-da-vinci-derivatives-kousshik-raj-30b50e3067e0',
  },
  {
    id: 19,
    title: 'Wells Fargo | Bharat Chaudhary',
    category: 'Software',
    company: 'Wells Fargo',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "There were two roles to apply one was Analytics and one was Technical Associate.",
    createdAt: 'October 4, 2022',
    cover: 'https://miro.medium.com/max/1100/1*V5dnkSXdgPnmvhFmxN-GqQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-wells-fargo-bharat-chaudhary-116465f623db',
  },
  {
    id: 20,
    title: 'Accenture | Pratyusha Mondal',
    category: 'Consultancy',
    company: 'Accenture',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Coming to this job, consulting is all about dealing with clients, and working in a team",
    createdAt: 'October 3, 2022',
    cover: '/assets/images/pratyusha.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-accenture-pratyusha-mondal-8bd3a1fcc618',
  },
  {
    id: 21,
    title: 'Mercedes-Benz | Renu Tanaji Rathod',
    category: 'Core',
    company: 'Mercedes-Benz',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "All the non-circuital branch students who are interested in research and simulations can apply for this role", 
    createdAt: 'September 30, 2022',
    cover: 'https://miro.medium.com/max/1100/1*jEqJiYOUADkAnPnfKMWw1g.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-mercedes-benz-renu-tanaji-rathod-97fe863e6541',
  },
  {
    id: 22,
    title: 'Dream11 | Pujan Burad',
    category: 'Software',
    company: 'Dream11',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who’s good at coding and problem solving, has a keen interest in software development should apply.",
    createdAt: 'September 28, 2022',
    cover: 'https://miro.medium.com/max/720/0*erLgqD0Clajf_D5e',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-dream-11-pujan-burad-cd8a8665db2',
  },
  {
    id: 23,
    title: 'Qualcomm | Deepanshi Pandey',
    category: 'Software',
    company: 'Qualcomm',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Students aspiring to work as software developers in the near future should apply for the role ",
    createdAt: 'September 19, 2022',
    cover: 'https://miro.medium.com/max/1100/1*RhZ29gn4EDRJfNPsU7no2w.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-qualcomm-deepanshi-pandey-18a17f216a40',
  },
  {
    id: 24,
    title: 'Google | Vinay Kumar Agarwal',
    category: 'Software',
    company: 'Google',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "An ideal person to apply for this role would be one who has passion for technology ",
    createdAt: 'September 17, 2022',
    cover: '/assets/images/vinay.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-google-vinay-kumar-agarwal-72632fc60e18',
  },
  {
    id: 25,
    title: 'Microsoft | Abhay Bhadoriya',
    category: 'Software',
    company: 'Microsoft',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who likes to solve problems and code them.If you don’t have internship experience in the development field, you can still go for it.",
    createdAt: 'September 12, 2022',
    cover: 'https://miro.medium.com/max/786/1*SI7UNAAjWxCiriqRvwRHCA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-microsoft-abhay-bhadoriya-75547627513a',
  },
  {
    id: 26,
    title: 'Sprinklr | Ayush Khatarkar',
    category: 'Software',
    company: 'Sprinklr',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "All the technology enthusiasts and students who love coding should apply for this job.",
    createdAt: 'June 28, 2022',
    cover: 'https://miro.medium.com/max/640/1*x4o8i5kkeX4z4pdTVHyqTw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-sprinklr-ayush-khatarkar-f24b68c92466',
  },
  {
    id: 27,
    title: 'Google | Tias Mondal',
    category: 'Software',
    company: 'Google',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "The only requirement is strong problem-solving skills (Not only coding).",
    createdAt: 'June 22, 2022',
    cover: 'https://miro.medium.com/max/1100/1*S8aAZoK2lNsw96M0vLQMyg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-google-tias-mondal-2e9c06d58a02',
  },
  {
    id: 28,
    title: 'AlphaGrep | Satyam Sharma',
    category: 'Software',
    company: 'AlphaGrep',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " In my case, I am really passionate about Software Development. Since my second year I developed a deep interest in Systems.",
    createdAt: 'June 16, 2022',
    cover: 'https://miro.medium.com/max/1100/1*NQrDzeBcQ0e0hjHUnbOJqw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-alphagrep-satyam-sharma-c88b6eeea5b2',
  },
  {
    id: 29,
    title: 'Quadeye | Arnab Maiti',
    category: 'Quant',
    company: 'Quadeye',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "For Quant role, HFTs usually prefer 9+ CGPA students from circuit departments.",
    createdAt: 'June 13, 2022',
    cover: 'https://miro.medium.com/max/1100/1*gwE6VPd-Y1yqRWrp_9eNpQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-quadeye-arnab-maiti-ce1f4fbb57e9',
  },
  {
    id: 30,
    title: 'Zomato | Vishvesh Pandya',
    category: 'Product',
    company: 'Zomato',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "One of the best and worst things about this role is that it doesn’t require any specific skills or degrees.",
    createdAt: 'June 8, 2022',
    cover: 'https://miro.medium.com/max/1100/1*HgA5Fkof1XWoaKrBqqJJNg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-zomato-vishvesh-pandya-7c7eea0d2d9f',
  },
  {
    id: 31,
    title: 'Zomato | Arnab Moitra',
    category: 'Product',
    company: 'Zomato',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Do go through important CS topics like OOPS, OS, DBMS and try to learn SQL, this definitely would help along the way.",
    createdAt: 'May 19, 2022',
    cover: 'https://miro.medium.com/max/1100/1*5PpsEw3SyEhExDEskkVMYg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-zomato-arnab-moitra-e825f794b7a7',
  },
  {
    id: 32,
    title: 'AMEX | Rachana Verma',
    category: 'Finance',
    company: 'AMEX',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who is into Management and Strategy should apply.",
    createdAt: 'May 16, 2022',
    cover: 'https://miro.medium.com/max/1100/1*gD9rXcCzt5ehZPzzpniSTw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-amex-rachana-verma-e1c339cd58f2',
  },
  {
    id: 33,
    title: 'Grofers | Prakhar Gaur',
    category: 'Product',
    company: 'Grofers',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Ideally, people who like business problem solving, interacting with different people, and thinking out of the box should apply.",
    createdAt: 'May 14, 2022',
    cover: 'https://miro.medium.com/max/1100/1*mE1MnEBIX2jEqu74ImlI-w.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-grofers-prakhar-gaur-762c60e17e92',
  },
  {
    id: 34,
    title: 'P&G | Keshav Bagri',
    category: 'FMCG',
    company: 'P&G',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "If you think you’re good with the fundamentals of your branch and you know to manage things efficiently while being in charge.",
    createdAt: 'May 10, 2022',
    cover: '/assets/images/keshav.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-p-g-keshav-bagri-3edaa5842cda',
  },
  {
    id: 35,
    title: 'BNY Mellon | Visesh Kaushik',
    category: 'Software',
    company: 'BNY Mellon',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who has a passion for technology, interested in Fin-Tech and targets Software Developer Role can apply for this job.",
    createdAt: 'May 2, 2022',
    cover: 'https://miro.medium.com/max/1100/1*fhVRWDdPm_U-HPoYILyEPw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-bny-mellon-visesh-kaushik-25b01d5a1204',
  },
 {
    id: 36,
    title: ' JP Morgan Chase | Gowri Jayaprakash',
    category: 'Quant',
    company: 'JP Morgan Chase',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who has an interest in math and is good at coding would find this role really interesting and rewarding.",
    createdAt: 'April 28, 2022',
    cover: 'https://miro.medium.com/max/306/1*1Ld0vWccrKzvvDy5wrG43g.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-jp-morgan-chase-gowri-jayaprakash-b5045d4f784',
  },
 {
    id: 37,
    title: '  Qualcomm | Jogadenu Kalyanraman',
    category: 'Core',
    company: 'Qualcomm',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Students who have good knowledge in Analog design, Analog and Digital Communication, and Signal Processing.",
    createdAt: 'April 24, 2022',
    cover: 'https://miro.medium.com/max/1100/1*NxLoaAZ84HCcVWt-1sPdrg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-qualcomm-jogadenu-kalyanraman-4f93f9cbab19',
  },
{
    id: 38,
    title: ' JLR | Vatsal Kapadia',
    category: 'Core',
    company: 'JLR',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who has interest in product engineering, system engineering, even AI and coding can apply for the job.",
    createdAt: 'December 2, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-vatsal-kapadia-working-at-jlr-b530e4de4280',
  },
{
    id: 39,
    title: '  Western Digitals | Shiva Surya',
    category: 'Core',
    company: '  Western Digitals',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "People  interested in the Electronics profile should apply for this.",
    createdAt: 'December 2, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-application-specific-integrated-circuit-asic-at-western-digitals-shiva-surya-e73897d5fde3',
  },
{
    id: 40,
    title: ' Barclays Global Services | Shishir Shahi',
    category: 'Software',
    company: 'Barclays Global Services',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " if you are interested in technology and its business implementation, Barclays will be a good fit for you.",
    createdAt: 'December 1, 2021',
    cover: 'https://miro.medium.com/max/1100/1*KhkpQ8lGPY6WVc2yrgaA8g.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-graduate-analyst-at-barclays-global-services-shishir-shahi-3ec4b92da7bb',
  },
{
    id: 41,
    title: 'Amazon | Meet Joshi',
    category: 'Software',
    company: 'Amazon',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " those who have a keen interest in the Software Development domain must apply.",
    createdAt: 'December 1, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-software-development-engineer-at-amazon-meet-joshi-53e9e764809d',
  },
{
    id: 42,
    title: 'JP Morgan Chase | Raj Kumar Singh',
    category: 'Quant',
    company: 'JP Morgan Chase',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who wants to work in the Finance industry has an interest in ML and Finance, coding can apply for this job.",
    createdAt: 'December 1, 2021',
    cover: '/assets/images/raj.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-qr-analyst-at-jp-morgan-chase-raj-kumar-singh-a4e1af919443',
  },
{
    id: 43,
    title: ' Cisco | Vineeth',
    category: 'Software',
    company: 'Cisco',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "For a software profile, there are more application-oriented roles, OS, networking oriented roles. There are few ML-related roles also.",
    createdAt: 'December 1, 2021',
    cover: 'https://miro.medium.com/max/786/1*v1xzKfjNgR0Bb_ErvPHsUA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-software-developer-at-cisco-vineeth-a59d86a3fd9b',
  },
{
    id: 44,
    title: ' Dr Reddy’s Laboratories | Shakul',
    category: 'Core',
    company: "Dr Reddy’s Laboratories",
    subCategory: ['skill', 'design', 'passion'],
    description:
      "The ideal candidate would be one who is interested in pharmaceutical manufacturing. The company  value  those who display knowledge in production challenges.",
    createdAt: 'December 1, 2021',
    cover: 'https://miro.medium.com/max/1100/1*z8WZvM9ipAXG-kpFh2e58w.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-shakul-from-dr-reddys-laboratories-8deb1d9bd7fd',
  },
{
    id: 45,
    title: 'Mercedes Benz | Anuraag Bhattacharya',
    category: 'Data',
    company: 'Mercedes Benz',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "People who are fairly proficient in Data Structures and Algorithms and interested to work in the field of ML/DL should apply.",
    createdAt: 'December 1, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-post-graduate-engineer-trainee-researcher-at-mercedes-benz-anuraag-bhattacharya-d82b7dd39b09',
  },
{
    id: 46,
    title: 'Google | Sohan Rudra',
    category: 'Software',
    company: 'Google',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Position is ideal for someone who has a keen interest in Machine Learning research and would like to explore the field more",
    createdAt: 'November 30, 2021',
    cover: 'https://miro.medium.com/max/1100/1*1g46yRxSr4zwhX88NbiXPQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-pre-doctoral-researcher-at-google-sohan-rudra-b4e9c87ad8ad',
  },
 
];
 

